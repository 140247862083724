<template>
  <div>
    <div class="pb-1">
      <b-badge
        v-for="purpose in aboutData.point_purposes"
        :key="purpose.slug"
        pill
        variant="light-primary"
      >
        {{ getPurposeTitle(purpose.slug) }}
      </b-badge>

      <b-badge
        v-if="aboutData.number_of_views"
        pill
        variant="light-secondary"
      >
        <feather-icon
          icon="EyeIcon"
          class="mr-25"
        />
        <small>
          {{ aboutData.number_of_views }}
        </small>
      </b-badge>

    </div>
    <offer-media
      v-if="!short && aboutData.media_files && aboutData.media_files.length"
      :media-files="aboutData.media_files"
      :display="!short"
      :el-id="elId"
      class="float-md-right d-flex justify-content-center m-1"
    />
    <h4>
      {{ aboutData.name }}
    </h4>
    <div
      v-if="!short"
      class="my-1"
      v-html="aboutData.description"
    />
    <div
      v-if="aboutData.address.country!==null "
      class="d-flex justify-content-start align-items-center mb-75"
    >
      <b-avatar
        rounded
        size="32"
        class="mr-75"
        variant="light-primary"
      >
        <feather-icon
          icon="MapPinIcon"
          size="18"
        />
      </b-avatar>

      <offer-open-map
        :data="aboutData"
      />

    </div>
    <div
      v-if="aboutData.location_radius!==null"
      class="d-flex justify-content-start align-items-center mb-75"
    >
      <b-avatar
        rounded
        size="32"
        class="mr-75"
        variant="light-primary"
      >
        <feather-icon
          icon="RadioIcon"
          size="18"
        />
      </b-avatar>
      <div>
        {{ $t('Within a radius of') }}
        <h6 class="mb-0">
          {{ aboutData.location_radius }} {{ $t('km') }}
        </h6>
      </div>
    </div>

    <!--          <b-media-->
    <!--            no-body-->
    <!--          >-->
    <!--            <b-media-aside class="mr-1">-->
    <!--              <b-avatar-->
    <!--                rounded-->
    <!--                variant="light-primary"-->
    <!--                size="34"-->
    <!--              >-->
    <!--                <feather-icon-->
    <!--                  icon="CalendarIcon"-->
    <!--                  size="18"-->
    <!--                />-->
    <!--              </b-avatar>-->
    <!--            </b-media-aside>-->
    <!--            <b-media-body>-->
    <!--              <h6 class="mb-0">-->
    <!--                Sat, May 25, 2020-->
    <!--              </h6>-->
    <!--              <small>10:AM to 6:PM</small>-->
    <!--            </b-media-body>-->
    <!--          </b-media>-->

  </div>
</template>

<script>
import {
  BAvatar, BBadge,
} from 'bootstrap-vue'
import { formatTimeOfStay, getFormattedAddress, getPostStatusProperty } from '@/mixins/functions'
import Ripple from 'vue-ripple-directive'
import { serviceOptions } from '@/mixins/options'
import { formatDateTime } from '@/filters/dateTime'
import OfferMedia from '@/views/apps/service/offer/details/OfferMedia.vue'
import OfferOpenMap from './OfferOpenMap.vue'

export default {
  components: {
    BAvatar,
    BBadge,
    OfferOpenMap,
    OfferMedia,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateTime,
  },
  mixins: [formatTimeOfStay, getFormattedAddress, getPostStatusProperty],
  props: {
    aboutData: {
      type: Object,
      required: true,
      default: () => {},
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
    elId: {
      type: String,
      default: () => '1',
    },
  },
  setup() {
    const { getPurposeTitle } = serviceOptions

    return {
      getPurposeTitle,
    }
  },

}
</script>
