import { render, staticRenderFns } from "./ProfileProcessedBadge.vue?vue&type=template&id=197cc630&scoped=true&"
import script from "./ProfileProcessedBadge.vue?vue&type=script&lang=js&"
export * from "./ProfileProcessedBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197cc630",
  null
  
)

export default component.exports