<template>
  <div
    v-if="user.subscriptions"
  >
    <!--      <b-avatar-->
    <!--        v-for="language in languages"-->
    <!--        :key="language.locale"-->
    <!--        v-b-tooltip.hover-->
    <!--        class="pull-up languages"-->
    <!--        :title="language.name"-->
    <!--        :src="language.img"-->
    <!--        square-->
    <!--      />-->
    <b-badge
      v-for="subscription in user.subscriptions"
      :key="subscription.id"
      variant="light-secondary"
    >
      {{ subscription.label }}
    </b-badge>
  </div>
</template>

<script>
import {
  BBadge,
  VBTooltip,

} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {

  },
  setup() {
    return {
    }
  },
}

</script>
<style scoped>

</style>
