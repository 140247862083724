<template>
  <div
    class="text-left"
  >
    <b-overlay
      :show="submittingFetch"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
      spinner-type="grow"
    >
      <b-tabs
        v-if="serviceOffers && serviceOffers.length"
        justified
        nav-class="font-weight-bold warning justify-content-between"
        pills
      >
        <b-tab
          v-for="(offer,key) in serviceOffers"
          :key="key"
          :title="getTypeTitle(offer.point_type.slug)"
        >
          <offer-about
            class="mt-1"
            :about-data="offer"
            :short="short"
            :el-id="key.toString()"
          />
        </b-tab>
      </b-tabs>
      <b-alert
        variant="warning"
        :show="displayAlert"
      >
        <div class="alert-body">
          <feather-icon
            icon="InfoIcon"
            class="mr-25"
          />
          <span class="align-middle">{{ $t('point.services_may_not_be_available_in_the_current_language') }} ({{ getLang($i18n.locale) }})</span>
        </div>
      </b-alert>

    </b-overlay>
  </div>
</template>
<script>
import {
  BTab, BTabs, BOverlay, BAlert,
} from 'bootstrap-vue'
import { locales, serviceOptions } from '@/mixins/options'
import OfferOpenMap from '@/views/apps/service/offer/details/OfferOpenMap.vue'
import OfferAbout from '@/views/apps/service/offer/details/OfferAbout.vue'
import { computed, ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

export default {
  components: {
    BTab,
    BTabs,
    BOverlay,
    BAlert,
    OfferAbout,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      OfferOpenMap,
      tabIndex: '',
    }
  },

  setup(props) {
    const displayAlert = ref(false)
    const submittingFetch = ref(false)
    const serviceOffers = computed(() => {
      if (props.user && props.user.service_offers) {
        const offers = props.user.service_offers.filter(offer => offer.lang === i18n.locale)
        displayAlert.value = !offers.length
        return offers
      }
      return []
    })

    const { getPurposeTitle, getTypeTitle } = serviceOptions
    const {
      getLang,
    } = locales()
    return {
      getPurposeTitle,
      getTypeTitle,
      serviceOffers,
      submittingFetch,
      getLang,
      displayAlert,
    }
  },

}
</script>
<style lang="scss" scoped>
.tab-pane.card-body{
  padding-top: 0!important;
}
</style>
