<template>
  <div>
    <b-tooltip
      :target="getElId(user.id)"
      placement="top"
    >
      {{ $t(message) }} {{ userProcessedDate }}
    </b-tooltip>
    <b-avatar
      :id="getElId(user.id)"
      :variant="userProcessedDate?'light-success':'light-secondary'"
      class="cursor-pointer"
      @click="displayTooltip()"
    >
      <feather-icon
        :icon="userProcessedDate?'UserCheckIcon':'UserXIcon'"
      />
    </b-avatar>
  </div>
</template>

<script>
import {
  BTooltip,
  BButton,
  BAvatar,

  VBTooltip,

} from 'bootstrap-vue'
import { useUserUi } from '@/views/apps/user/useUser'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTooltip,
    BButton,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    getElId(id) {
      return 'processed-'.concat(id.toString())
    },
    showTooltip(id) {
      this.$root.$emit('bv::show::tooltip', id)
    },
    hideTooltip(id) {
      this.$root.$emit('bv::hide::tooltip', id)
    },
    displayTooltip() {
      this.showTooltip(this.getElId(this.user.id))
      setTimeout(code => {
        this.hideTooltip(code)
      }, 1000)
    },
  },
  setup(props) {
    const { isUserProcessed } = useUserUi()
    let message = 'user.user_data_unchecked_by_manager'
    const userProcessedDate = isUserProcessed(props.user)
    if (userProcessedDate) {
      message = 'user.user_data_checked_by_manager'
    }

    return {
      userProcessedDate,
      message,
    }
  },
}

</script>
<style scoped>

</style>
