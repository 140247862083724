<template>
  <div
    v-if="display && images.length"
  >
    <gallery
      :id="id"
      :images="images"
      :index="mediaFileIndex"
      class="vue-gallery"
      @close="mediaFileIndex = null"
    />
    <div class="gallery-wrapper">
      <div
        v-for="(image, imageIndex) in images"
        :ref="id+imageIndex"
        :key="imageIndex"
        class="image"
        :style="{ backgroundImage: 'url(' + image + ')', width: '244px', height: '244px'}"
        @click="mediaFileIndex = imageIndex"
      >
        <div>
          <feather-icon
            icon="CameraIcon"
            size="14"
          />
          <span class="align-middle">{{ images.length }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VueGallery from 'vue-gallery'
import { isMobile } from '@/mixins/functions'
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    gallery: VueGallery,
  },
  mixins: [isMobile],
  props: {
    mediaFiles: {
      type: Array,
      default: () => [],
    },
    resizeThumbnails: {
      type: Boolean,
      default: () => false,
    },
    display: {
      type: Boolean,
      default: () => true,
    },
    elId: {
      type: String,
      default: () => '1',
    },
  },
  data() {
    return {
      mediaFileIndex: null,
      mediaFilesThumbnailWidth: null,
    }
  },
  watch: {
    mediaFiles: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.images = this.mediaFiles.map(el => el.url)
          this.resizeMediaFilesThumbnails()
        })
      },
    },
  },
  mounted() {
    this.resizeMediaFilesThumbnails()
    // window.addEventListener('resize', event => {
    // this.resizeMediaFilesThumbnails()
    // })
  },
  methods: {
    defineThumbnailSizes() {
      const elem = document.getElementById(this.id)
      if (!elem) return
      this.mediaFilesThumbnailWidth = elem.clientWidth
    },
    resizeMediaFilesThumbnails() {
      const elem = this.$refs[this.id]
      if (!elem) return
      this.defineThumbnailSizes()
      if (elem.length) {
        elem.forEach(file => {
          this.setStyle(file, this.mediaFilesThumbnailWidth)
        })
      }
    },
    setStyle(e, size) {
      const s = size - size / 10
      e.style.width = s.toString().concat('px')
      e.style.height = s.toString().concat('px')
    },
  },
  setup(props) {
    const images = ref([])
    const id = computed(() => 'mediaFilesOffers'.concat(props.elId))

    return {
      images,
      id,
    }
  },
}
</script>
<style scoped>
.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 5px;
}
.gallery-wrapper .image {
  background-position: center center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-color: rgb(248, 248, 248)
}
.dark-layout .gallery-wrapper .image{
  background-color: rgba(41, 128, 185, 0.12)
}
.gallery-wrapper .image:nth-of-type(1n+2) {
  display: none;
}
.gallery-wrapper .image div{
  color: gray;
  filter: opacity(0.8);
  padding: 1em 0 0 1em;
}

.gallery-wrapper .image span{
  padding-left: 0.2em;
  font-size: 1em;
}

</style>
