import { postStatuses, serviceOptions } from '@/mixins/options'
import i18n from '@/libs/i18n'

export const formatTimeOfStay = {
  methods: {
    formatTimeOfStay(timeInMinutes) {
      const hours = Math.floor(timeInMinutes / 60)
      const minutes = timeInMinutes % 60
      if (!hours) {
        return `${minutes} min`
      }
      return `${hours} hrs : ${minutes} min`
    },
  },
}

export const getPostStatusProperty = {
  mixins: [postStatuses],
  methods: {
    getPostStatusProperty(slug, property) {
      const postStatus = this.postStatuses.filter(status => status.slug === slug)
      return postStatus[0][property]
    },
  },
}

export const getUserAvatarText = {
  methods: {
    getUserAvatarText(UserName) {
      return UserName ? UserName.substring(0, 1).toUpperCase() : ''
    },
  },
}

export const isMobile = {
  methods: {
    isMobile() {
      return window.innerWidth <= 767
    },
  },
}

export const numbersOnly = {
  methods: {
    numbersOnly(e) {
      const symbol = String.fromCharCode(e.keyCode)
      if (/^[0-9]+$/.test(symbol)) return true
      e.preventDefault()
    },
  },
}
export const getFormattedAddress = {
  methods: {
    getFormattedAddress(location) {
      if (!location) return
      const address = JSON.parse(JSON.stringify(location))

      // if (address.apartment_number) {
      //   if (location.country_code) {
      //     const lang =  location.country_code==='ua'?'uk':location.country_code
      //     const apt = this.$i18n.t('Apt.', lang).concat(' $&')
      //     address.apartment_number = address.apartment_number.replace(/.+/g, apt)
      //   }
      // }
      if (!address.house_number) {
        address.road = ''
      }
      const lang = i18n.locale
      let addr = []
      switch (lang) {
        case 'en':
          addr = [
            address.house_number,
            address.road,
            address.apartment_number,
            address.locality,
            address.state,
            address.postcode,
            address.country,
          ]
          break
        case 'es':
          addr = [
            address.road,
            address.house_number,
            address.apartment_number,
            address.postcode,
            address.locality,
            address.state,
            address.country,
          ]
          break
        default:
          addr = [
            address.road,
            address.house_number,
            address.apartment_number,
            address.locality,
            address.state,
            address.postcode,
            address.country,
          ]
      }

      return addr.filter(Boolean).join(', ')
    },
  },
}

export const extendPointTypesAndPurposes = options => {
  // Add icons, tooltips etc
  const result = []
  options.forEach(obj => {
    const type = serviceOptions.categories.find(({ slug }) => slug === obj.slug)
    if (type && type.point_purposes) {
      const res = []
      obj.point_purposes.forEach(fetchedPurpose => {
        const purpose = type.point_purposes.find(({ slug }) => slug === fetchedPurpose.slug)
        if (purpose) {
          res.push({ ...fetchedPurpose, ...purpose })
        }
      })
      type.point_purposes = res
    }
    result.push({ ...obj, ...type })
  })
  return result
}
export const translateApiErrors = errors => {
  Object.keys(errors).forEach(field => {
    const errorMessages = errors[field]
    errorMessages.forEach((message, index) => {
      errorMessages[index] = i18n.t(message)
    })
  })
}

export const findPath = (ob, key) => {
  const path = []
  const keyExists = obj => {
    if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
      return false
    }
    if (obj.hasOwnProperty(key)) {
      return true
    }
    if (Array.isArray(obj)) {
      const parentKey = path.length ? path.pop() : ''

      for (let i = 0; i < obj.length; i++) {
        path.push(`${parentKey}[${i}]`)
        const result = keyExists(obj[i], key)
        if (result) {
          return result
        }
        path.pop()
      }
    } else {
      for (const k in obj) {
        path.push(k)
        const result = keyExists(obj[k], key)
        if (result) {
          return result
        }
        path.pop()
      }
    }
    return false
  }

  keyExists(ob)

  return path.join('.')
}
export const scrollToTop = () => {
  const rootEle = document.documentElement
  rootEle.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
export const _ = null
